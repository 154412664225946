import styled from "styled-components";

const BlockWrapper = styled.section`
  min-height: 500px;
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 40px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    min-height: 500px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 991px) {
    min-height: 370px;
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    min-height: auto;
    display: block;
  }
  ${"" /* left: 0;
   ${props => props.index === 0 && `background-color: #9b8cee11;`}
   ${props => props.index === 1 && `background-color: #9b8cee33;`}
   ${props => props.index === 2 && `background-color: #9b8cee55;`} */}
`;

const SectionObject = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;

  ${props => (props.left ? "right: 0" : "left: 0;")};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    top: auto;
    left: auto;
  }
  img {
    max-width: 93%;
    height: auto;
  }
  .objectWrapper {
    ${props => (props.left ? "margin-right: auto;" : "margin-left: auto;")};

    position: relative;
    .dashboardWrapper {
      position: absolute;
      top: 4vw;
      left: 0;
      width: 80%;
      padding: 1rem;
    }
  }
`;

export { SectionObject };

export default BlockWrapper;
