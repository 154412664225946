import React from "react";

export function UploadIcon({ size = 56, style }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect width="56" height="56" rx="21" fill="#3E21DE" fill-opacity="0.1" />
      <path
        d="M28.1323 19.6009V32.4343"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.4665 36.1H20.7998"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.5483 24.1843L28.1325 19.6L32.716 24.1843"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function AiIcon({ size = 56, style }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect width="56" height="56" rx="21" fill="#3E21DE" fill-opacity="0.05" />
      <path
        d="M25.1776 33.0424C25.8935 33.7584 25.8935 34.9192 25.1776 35.6351C24.4616 36.3511 23.3008 36.3511 22.5848 35.6351C21.8689 34.9192 21.8689 33.7584 22.5848 33.0424C23.3008 32.3264 24.4616 32.3264 25.1776 33.0424"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.4163 33.0424C34.1323 33.7584 34.1323 34.9192 33.4163 35.6351C32.7004 36.3511 31.5396 36.3511 30.8236 35.6351C30.1076 34.9192 30.1076 33.7584 30.8236 33.0424C31.5396 32.3264 32.7004 32.3264 33.4163 33.0424"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.6317 25.2077C23.3476 25.9236 23.3476 27.0845 22.6317 27.8004C21.9157 28.5164 20.7549 28.5164 20.0389 27.8004C19.323 27.0845 19.323 25.9236 20.0389 25.2077C20.7549 24.4917 21.9157 24.4917 22.6317 25.2077"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.2967 20.3649C30.0127 21.0809 30.0127 22.2417 29.2967 22.9576C28.5807 23.6736 27.4199 23.6736 26.704 22.9576C25.988 22.2417 25.988 21.0809 26.704 20.3649C27.4199 19.649 28.5807 19.649 29.2967 20.3649"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M35.9617 25.2077C36.6777 25.9236 36.6777 27.0845 35.9617 27.8004C35.2458 28.5164 34.085 28.5164 33.369 27.8004C32.653 27.0845 32.653 25.9236 33.369 25.2077C34.085 24.4917 35.2458 24.4917 35.9617 25.2077"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.2967 27.1107C30.0127 27.8267 30.0127 28.9875 29.2967 29.7034C28.5807 30.4194 27.4199 30.4194 26.704 29.7034C25.988 28.9875 25.988 27.8267 26.704 27.1107C27.4199 26.3947 28.5807 26.3947 29.2967 27.1107"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.0454 29.9067L31.0712 32.84"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.9555 29.9067L24.9297 32.8308"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.2494 27.9084L23.0869 27.0009"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.0003 26.57V23.4992"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.751 27.9084L32.9135 27.0009"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function ResultsIcon({ size = 56, style }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect
        y="0.00012207"
        width="56"
        height="56"
        rx="21"
        fill="#3E21DE"
        fill-opacity="0.05"
      />
      <path
        d="M25.25 36.2501H30.75"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.749 32.7292V33.7063H25.249V32.7292C25.249 31.7602 24.7999 30.8555 24.0528 30.2377C22.7594 29.1661 21.9087 27.5802 21.8198 25.7909C21.6502 22.3616 24.4689 19.3467 27.9019 19.2945C31.3641 19.2404 34.1874 22.0298 34.1874 25.4792C34.1874 27.4061 33.3047 29.1239 31.9224 30.2569C31.1808 30.8647 30.749 31.7703 30.749 32.7292Z"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.8286 31.1625H31.1627"
        stroke="#3E21DE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
