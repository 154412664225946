import React from "react";

const QuotesIcon = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 46.195 46.195"
    {...props}
  >
    <g fill={props.color}>
      <path d="M35.765 8.264c-5.898 0-10.555 4.782-10.555 10.68s4.844 10.68 10.742 10.68c.059 0 .148-.008.207-.009-2.332 1.857-5.261 2.976-8.467 2.976-1.475 0-2.662 1.196-2.662 2.67s.949 2.67 2.424 2.67c10.469-.001 18.741-8.518 18.741-18.987v-.007c0-5.895-4.534-10.673-10.43-10.673zM10.75 8.264C4.852 8.264.187 13.046.187 18.944s4.84 10.68 10.739 10.68c.059 0 .146-.008.205-.009-2.332 1.857-5.262 2.976-8.468 2.976-1.475 0-2.663 1.196-2.663 2.67s.964 2.67 2.439 2.67c10.469-.001 18.756-8.518 18.756-18.987v-.007c0-5.895-4.549-10.673-10.445-10.673z" />
    </g>
  </svg>
);

export default QuotesIcon;
