import React, { Fragment, useState, useRef } from "react";
import styled from "styled-components";
import { plugin } from "postcss";
import { APP_SIGN_UP_URL } from "../utils/constants";

const PluginsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  align-items: center;
  ${"" /* justify-content: flex-end; */}

  & > p {
    font-size: 16px;
    color: #343d48cc;
    margin-right: 1rem;
  }

  & > a {
    margin-right: 1rem;
  }

  & > a:last-child {
    margin-right: 0;
  }
`;
const AvailablePlugins = (props) => {
  const height = props.size === "small" ? "24" : "32";

  return (
    <PluginsContainer {...props}>
      <p>{props.text || "Available plugins:"}</p>
      {/* <a href={APP_SIGN_UP_URL} target="_blank" rel="noreferrer">
        <svg
          id={`sketch-icon-${props.id}`}
          height={height}
          viewBox="0 0 40 40"
          fill="none"
          // style="margin: 0.5rem"
        >
          <title>Sketch</title>
          <path
            d="M8.12756 14.3726L19.9583 39.954L0.0729675 14.3726H8.12756Z"
            fill="#EA6C00"
          ></path>
          <path
            d="M31.7924 14.3726L19.9617 39.954L39.847 14.3726H31.7924Z"
            fill="#EA6C00"
          ></path>
          <path
            d="M8.12714 14.3726H31.7886L19.9579 39.954L8.12714 14.3726Z"
            fill="#FDAD00"
          ></path>
          <path
            d="M19.9579 0.195312L8.74983 1.50415L8.12714 14.3725L19.9579 0.195312Z"
            fill="#FDD231"
          ></path>
          <path
            d="M19.9579 0.195312L31.1659 1.50415L31.7886 14.3725L19.9579 0.195312Z"
            fill="#FDD231"
          ></path>
          <path
            d="M39.847 14.3728L31.1698 1.50439L31.7925 14.3728H39.847Z"
            fill="#FDAD00"
          ></path>
          <path
            d="M0.0729675 14.3728L8.75026 1.50439L8.12756 14.3728H0.0729675Z"
            fill="#FDAD00"
          ></path>
          <path
            d="M19.9579 0.195312L8.12714 14.3725H31.7886L19.9579 0.195312Z"
            fill="#FEEEB7"
          ></path>
        </svg>
      </a> */}
      <a href={APP_SIGN_UP_URL} target="_blank" rel="noreferrer">
        <svg
          height={height}
          viewBox="0 0 26 40"
          fill="none"
          id={`figma-icon-${props.id}`}
        >
          <title>Figma</title>
          <path
            d="M6.42848 40.0003C9.97701 40.0003 12.857 37.0137 12.857 33.3337L12.857 26.667H6.42848C2.87996 26.667 0 29.6537 0 33.3337C0 37.0137 2.87996 40.0003 6.42848 40.0003Z"
            fill="#0ACF83"
          ></path>
          <path
            d="M0 20.0002C0 16.3202 2.87997 13.3335 6.4285 13.3335H12.857L12.857 26.6668H6.4285C2.87997 26.6668 0 23.6802 0 20.0002Z"
            fill="#A259FF"
          ></path>
          <path
            d="M0.000488281 6.66667C0.000488281 2.98667 2.88046 0 6.42899 0L12.8575 0L12.8575 13.3333L6.42899 13.3333C2.88046 13.3333 0.000488281 10.3467 0.000488281 6.66667Z"
            fill="#F24E1E"
          ></path>
          <path
            d="M12.8569 0H19.2854C22.8339 0 25.7139 2.98667 25.7139 6.66667C25.7139 10.3467 22.8339 13.3333 19.2854 13.3333H12.8569L12.8569 0Z"
            fill="#FF7262"
          ></path>
          <path
            d="M25.7142 20.0002C25.7142 23.6802 22.8342 26.6668 19.2857 26.6668C15.7371 26.6668 12.8572 23.6802 12.8572 20.0002C12.8572 16.3202 15.7371 13.3335 19.2857 13.3335C22.8342 13.3335 25.7142 16.3202 25.7142 20.0002Z"
            fill="#1ABCFE"
          ></path>
        </svg>
      </a>
      <a href={APP_SIGN_UP_URL} target="_blank" rel="noreferrer">
        <svg
          height={height}
          viewBox="0 0 40 40"
          fill="none"
          id={`adobe-icon-${props.id}`}
        >
          <title>Adobe XD</title>
          <path
            d="M32.9168 40H7.0832C3.1664 40 0 36.7524 0 32.7352V7.26482C0 3.24759 3.1664 0 7.0832 0H32.9168C36.8336 0 40 3.24759 40 7.26482V32.7352C40 36.7524 36.8336 40 32.9168 40Z"
            fill="#FF26BE"
          ></path>
          <path
            d="M32.2832 38.2901H7.71684C4.38404 38.2901 1.66724 35.5036 1.66724 32.0853V7.91468C1.66724 4.49642 4.38404 1.70996 7.71684 1.70996H32.2672C35.6176 1.70996 38.3168 4.49642 38.3168 7.91468V32.0689C38.3328 35.5036 35.616 38.2901 32.2832 38.2901Z"
            fill="#2E001F"
          ></path>
          <path
            d="M15.816 18.7171L20.4832 27.9134C20.5664 28.0496 20.5168 28.1874 20.3824 28.1874H17.4816C17.2976 28.1874 17.2144 28.1366 17.1312 27.9659C16.064 25.7095 14.9808 23.4531 13.864 21.026H13.8304C12.8304 23.3169 11.7296 25.7275 10.664 27.9839C10.5808 28.1202 10.4976 28.1727 10.3632 28.1727H7.61601C7.44961 28.1727 7.43201 28.0365 7.51681 27.9331L12.0832 19.0092L7.66721 9.98354C7.56641 9.84733 7.66721 9.74395 7.76641 9.74395H10.6336C10.8 9.74395 10.8672 9.77841 10.9328 9.93267C11.9824 12.1891 13.0496 14.5144 14.0496 16.7872H14.0832C15.0496 14.5308 16.1168 12.1891 17.1504 9.94908C17.2336 9.81287 17.2832 9.72754 17.4512 9.72754H20.1344C20.2672 9.72754 20.3184 9.83092 20.2352 9.96713L15.816 18.7171ZM21.3008 21.5035C21.3008 17.5208 23.8848 14.4094 27.984 14.4094C28.3344 14.4094 28.5168 14.4094 28.8512 14.4438V9.91462C28.8512 9.81123 28.9344 9.74395 29.0176 9.74395H31.6512C31.784 9.74395 31.8176 9.79482 31.8176 9.88015V25.5733C31.8176 26.0344 31.8176 26.6153 31.9008 27.2488C31.9008 27.3522 31.8672 27.385 31.768 27.4375C30.368 28.1218 28.9008 28.4287 27.5008 28.4287C23.8832 28.4434 21.2992 26.1542 21.3008 21.5035ZM28.8496 17.1286C28.616 17.0252 28.2832 16.9579 27.8832 16.9579C25.7824 16.9579 24.3168 18.6153 24.3168 21.369C24.3168 24.5148 25.8176 25.78 27.7008 25.78C28.1008 25.78 28.5344 25.7292 28.8512 25.5913V17.1286H28.8496Z"
            fill="#FFD9F2"
          ></path>
        </svg>
      </a>
      {props.hasChrome && (
        <a
          href="https://konsalex.typeform.com/to/REVRmg"
          target="_blank"
          rel="noreferrer"
          data-tip="Coming Soon"
        >
          <svg
            height={height}
            viewBox="0 0 33 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Chrome Browser logo</title>

            <path
              d="M11.9322 0.00334136C11.9322 0.00334136 18.9652 -0.312266 22.6939 6.75697H11.3337C11.3337 6.75697 9.18985 6.68787 7.35848 9.28874C6.83241 10.3804 6.26693 11.5049 6.90151 13.7211C5.98736 12.1723 2.04834 5.31358 2.04834 5.31358C2.04834 5.31358 4.82671 0.282813 11.9321 0.00334136H11.9322Z"
              fill="#EF3F36"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M22.3289 17.9494C22.3289 17.9494 19.0836 24.1999 11.1003 23.8908C12.0868 22.1843 16.782 14.053 16.782 14.053C16.782 14.053 17.9164 12.2315 16.5782 9.34459C15.8975 8.34174 15.2037 7.29286 12.9679 6.73382C14.7665 6.71748 22.6742 6.73382 22.6742 6.73382C22.6742 6.73382 25.6366 11.6561 22.3289 17.9494Z"
              fill="#FCD900"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M1.58483 17.9987C1.58483 17.9987 -2.20629 12.0638 2.05497 5.30359C3.03812 7.01011 7.73339 15.1414 7.73339 15.1414C7.73339 15.1414 8.74613 17.0353 11.9125 17.318C13.1193 17.2293 14.3752 17.1536 15.9799 15.4998C15.0953 17.0649 11.1265 23.904 11.1265 23.904C11.1265 23.904 5.37912 24.0092 1.58474 17.9987H1.58483Z"
              fill="#61BC5B"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M11.0972 23.9501L12.6951 17.282C12.6951 17.282 14.4509 17.1438 15.924 15.5294C15.0099 17.1373 11.0972 23.9501 11.0972 23.9501V23.9501Z"
              fill="#5AB055"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M6.57935 12.0506C6.57935 9.11115 8.96321 6.72729 11.9027 6.72729C14.8421 6.72729 17.226 9.11115 17.226 12.0506C17.226 14.9901 14.8421 17.3739 11.9027 17.3739C8.96321 17.3706 6.57935 14.9901 6.57935 12.0506V12.0506Z"
              fill="white"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M7.47021 12.0506C7.47021 9.6044 9.45294 7.61841 11.9025 7.61841C14.3488 7.61841 16.3348 9.60114 16.3348 12.0506C16.3348 14.497 14.3522 16.483 11.9025 16.483C9.45621 16.483 7.47021 14.497 7.47021 12.0506V12.0506Z"
              fill="#178BCB"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M22.6711 6.73719L16.0917 8.66725C16.0917 8.66725 15.0987 7.2106 12.9648 6.73719C14.816 6.72729 22.6711 6.73719 22.6711 6.73719V6.73719Z"
              fill="#EACA05"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M6.7733 13.4777C5.84926 11.8764 2.04834 5.31348 2.04834 5.31348L6.92121 10.1337C6.92121 10.1337 6.42137 11.1629 6.60887 12.6359L6.77321 13.4777H6.7733Z"
              fill="#DF3A32"
              style={{ opacity: 0.3 }}
            />
            <path
              d="M30.9609 5.48767L29.2503 4.5L30.9609 3.51233C31.047 3.46257 31.1099 3.38066 31.1356 3.28461C31.1613 3.18855 31.1478 3.08622 31.0981 3.0001C31.0484 2.91397 30.9665 2.85112 30.8705 2.82535C30.7744 2.79958 30.6721 2.813 30.5859 2.86267L28.8752 3.85041V1.875C28.8752 1.77554 28.8357 1.68016 28.7654 1.60984C28.695 1.53951 28.5997 1.5 28.5002 1.5C28.4007 1.5 28.3054 1.53951 28.235 1.60984C28.1647 1.68016 28.1252 1.77554 28.1252 1.875V3.85041L26.4144 2.86266C26.3283 2.81299 26.226 2.79957 26.1299 2.82534C26.0339 2.85111 25.952 2.91396 25.9023 3.00008C25.8526 3.08621 25.8391 3.18854 25.8648 3.2846C25.8905 3.38065 25.9534 3.46256 26.0394 3.51232L27.7501 4.5L26.0394 5.48767C25.9534 5.53743 25.8905 5.61934 25.8648 5.71539C25.8391 5.81145 25.8526 5.91378 25.9023 5.9999C25.952 6.08602 26.0339 6.14888 26.1299 6.17465C26.226 6.20042 26.3283 6.187 26.4144 6.13733L28.1252 5.14959V7.125C28.1252 7.22446 28.1647 7.31984 28.235 7.39016C28.3054 7.46049 28.4007 7.5 28.5002 7.5C28.5997 7.5 28.695 7.46049 28.7654 7.39016C28.8357 7.31984 28.8752 7.22446 28.8752 7.125V5.14959L30.5859 6.13734C30.6721 6.18701 30.7744 6.20043 30.8705 6.17466C30.9665 6.14889 31.0484 6.08604 31.0981 5.99991C31.1478 5.91379 31.1613 5.81146 31.1356 5.7154C31.1099 5.61935 31.047 5.53744 30.9609 5.48768V5.48767Z"
              fill="#1D0F68"
            />
          </svg>
        </a>
      )}
    </PluginsContainer>
  );
};

export default AvailablePlugins;
