import React, { Fragment } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Button from "reusecore/src/elements/Button";
import Container from "../../components//Container";
import GlideCarousel from "../../components//GlideCarousel";
import GlideSlide from "../../components//GlideCarousel/glideSlide";
import TestimonialSectionWrapper, {
  TextWrapper,
  ImageWrapper,
  RoundWrapper,
  ClientName
} from "./testimonialSection.style";
import data from "./data.json";
import DribbbleIcon from "./DribbbleIcon";
import LinkedInIcon from "./LinkedInIcon";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import QuotesIcon from "./QuotesIcon";

const TestimonialSection = ({
  sectionSubTitle,
  btnWrapperStyle,
  commentStyle,
  nameStyle,
  btnStyle,
  designationStyle,
  socialIconStyle,
  startAt
}) => {
  // Glide js options
  const glideOptions = {
    type: "carousel",
    autoplay: false,
    perView: 1,
    startAt: startAt || 0,
    rewind: true
  };

  return (
    <TestimonialSectionWrapper
      id="testimonial_section"
      style={{ backgroundColor: "#f7f8fd" }}
    >
      <Container>
        <Heading
          as="h2"
          content="What leading designers say about VisualEyes"
          {...sectionSubTitle}
        />
        <GlideCarousel
          options={glideOptions}
          buttonWrapperStyle={btnWrapperStyle}
          prevButton={
            <Button
              icon={<FiArrowLeft />}
              variant="textButton"
              aria-label="prev"
              {...btnStyle}
            />
          }
          nextButton={
            <Button
              icon={<FiArrowRight />}
              variant="textButton"
              aria-label="next"
              {...btnStyle}
            />
          }
        >
          <Fragment>
            {data.testimonials.map((item, index) => (
              <GlideSlide key={index}>
                <Fragment>
                  <TextWrapper>
                    <i>
                      <QuotesIcon
                        width={20}
                        height={20}
                        color="rgba(52, 61, 72, 0.2)"
                      />
                    </i>
                    <Text content={item.comment} {...commentStyle} />
                    <ClientName>
                      <Heading as="h3" content={item.name} {...nameStyle} />
                      <Heading
                        as="h4"
                        content={item.designation}
                        {...designationStyle}
                      />
                      <a
                        href={item.social_url}
                        target="_blank"
                        rel="noreferrer"
                        {...socialIconStyle}
                      >
                        {item.social === "dribbble" && (
                          <DribbbleIcon width={20} color="#3E21DE" />
                        )}
                        {item.social === "linkedin" && (
                          <LinkedInIcon width={20} color="#3E21DE" />
                        )}
                      </a>
                    </ClientName>
                  </TextWrapper>
                  <ImageWrapper>
                    <RoundWrapper>
                      <img
                        src={item.avatar_url}
                        style={{ width: "100%" }}
                        alt="Client Image"
                      />
                    </RoundWrapper>
                    {/* <Button
                      variant="fab"
                      icon={<i className={item.social_icon} />}
                      aria-label="social"
                    /> */}
                  </ImageWrapper>
                </Fragment>
              </GlideSlide>
            ))}
          </Fragment>
        </GlideCarousel>
      </Container>
    </TestimonialSectionWrapper>
  );
};

// TestimonialSection style props
TestimonialSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  btnStyle: PropTypes.object,
  btnWrapperStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  commentStyle: PropTypes.object,
  designationStyle: PropTypes.object
};

// TestimonialSection default style
TestimonialSection.defaultProps = {
  // sub section default style
  sectionSubTitle: {
    display: "block",
    fontSize: "32px",
    fontWeight: "700",
    color: "#33325C",
    mb: "60px",
    ml: "auto",
    mr: "auto",
    pl: "12px",
    maxWidth: "954px",
    textAlign: "center"
  },
  socialIconStyle: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  // client comment style
  commentStyle: {
    color: "#0f2137",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "1.72",
    mb: "47px"
  },
  // client name style
  nameStyle: {
    color: "#343d48",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "30px",
    mb: 0
  },
  // client designation style
  designationStyle: {
    color: "rgba(52, 61, 72, 0.8)",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "30px",
    mb: 0,
    ml: ["0", "10px"],
    mr: ["0", "20px"]
  },
  // glide slider nav controls style
  btnWrapperStyle: {
    position: "absolute",
    bottom: "62px",
    left: "12px"
  },
  // next / prev btn style
  btnStyle: {
    minWidth: "auto",
    minHeight: "auto",
    mr: "13px",
    fontSize: "16px",
    color: "#343d484d"
  }
};

export default TestimonialSection;
