import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import Card from "reusecore/src/elements/Card";
import Button from "reusecore/src/elements/Button";
import Image from "reusecore/src/elements/Image";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/Container";
import BlockWrapper, { SectionObject } from "./Block.style";
import { FiArrowRight } from "react-icons/fi";
import { buttonStyle } from "reusecore/src/theme/customVariant";
import { navigate } from "gatsby";
import Img from "../../../components/Image";

const Block = ({
  titleStyle,
  descriptionStyle,
  textAreaStyle,
  imageWrapper,
  title,
  content,
  index,
  left,
  image,
  additional,
  icon,
}) => {
  return (
    <BlockWrapper id={`feature-${index}`} left={left} index={index}>
      <SectionObject left={left}>
        <Card className="objectWrapper" {...imageWrapper}>
          <Fade left={!left} right={left}>
            {/* <Image src={image} alt={`feature-${index}-image`} /> */}
            <Img filename={image} alt={`feature-${index}-image`} />
          </Fade>
        </Card>
      </SectionObject>
      <Container>
        <Box {...textAreaStyle(left)}>
          {icon}
          <FeatureBlock
            title={<Heading as="h2" content={title} {...titleStyle} />}
            description={
              <>
                {content.map((text) => (
                  <Text as="h3" content={text} {...descriptionStyle} />
                ))}
              </>
            }
            additionalContent={additional}
          />
        </Box>
      </Container>
    </BlockWrapper>
  );
};

Block.propTypes = {
  titleStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  btnStyle: PropTypes.object,
};

Block.defaultProps = {
  textAreaStyle: (isLeft) => ({
    width: ["100%", "100%", "45%"],

    ml: [0, 0, isLeft ? "auto" : "50%"],
    mr: [0, 0, isLeft ? "50%" : "auto"],
  }),
  imageWrapper: {
    boxShadow: "none",
    width: "100%",
  },
  titleStyle: {
    fontSize: ["24px", "32px"],
    fontWeight: "900",
    color: "#32325d",
    letterSpacing: "-0.010em",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
    lineHeight: "1.5",
  },
  descriptionStyle: {
    fontSize: ["18px", "22px"],
    color: "#343d48cc",
    lineHeight: "1.75",
    fontWeight: "400",

    mb: "1rem",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
  },
};

export default Block;
