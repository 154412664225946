import React from "react";
import AvailablePlugins from "../../components/AvailablePlugins";
import Button from "reusecore/src/elements/Button";
import { FiArrowRight } from "react-icons/fi";
import { navigate } from "gatsby";
import redirect from "../../utils/redirect";
import { UploadIcon, AiIcon, ResultsIcon } from "./Icons";

export default {
  how: [
    {
      title: "Upload your Design",
      content: [
        "Choose between our versatile web app and the design tools' plugins to upload your work. Select the target device for your design and kick start the process!",
        // "Use the VisualEyes plugin to upload your designs to our platform with 1 click."
      ],
      image: "/how/upload.jpg",
      icon: <UploadIcon size={48} style={{ marginBottom: "1rem" }} />,
      // "https://d34cuim5v38p5e.cloudfront.net/visualeyes/How+It+Works/1.webp",
      additional: <AvailablePlugins id="how" />,
    },
    {
      title: "Process your design carefully",
      content: [
        "Our intelligent algorithms forecast user behaviour patterns based on extensive data sets from large scale studies. Design confidentiality is an essential part of our process.",
      ],
      image: "/how/ai.jpg",
      icon: <AiIcon size={48} style={{ marginBottom: "1rem" }} />,
      // "https://d34cuim5v38p5e.cloudfront.net/visualeyes/How+It+Works/2.webp",
      additional: (
        <Button
          id="how-process-design"
          title={"Learn More"}
          icon={<FiArrowRight />}
          variant="textButton"
          iconPosition="right"
          onClick={() =>
            redirect(
              "https://www.notion.so/How-accurate-is-our-technology-5414ce38221e492d9f28f885b786ca8e"
            )
          }
          style={{ textTransform: "capitalize", fontSize: "18px" }}
        />
      ),
    },
    {
      title: "Instant Results",
      content: [
        "Receive in-depth insights within seconds without leaving your design tool or browser window. Days of research compressed into a single click. Isn't that a reason to celebrate?",
      ],
      image: "/how/results.jpg",
      icon: <ResultsIcon size={48} style={{ marginBottom: "1rem" }} />,

      // image:
      // "https://d34cuim5v38p5e.cloudfront.net/visualeyes/How+It+Works/3.webp",
      additional: (
        <Button
          id="how-results"
          title={"See Visualeyes' Insights"}
          icon={<FiArrowRight />}
          variant="textButton"
          iconPosition="right"
          onClick={() => {
            navigate("/features");
          }}
          style={{ textTransform: "capitalize", fontSize: "18px" }}
        />
      ),
    },
  ],
};
