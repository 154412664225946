import React from "react";
import HowItWorks from "../containers/HowItWorks";
import TestimonialSection from "../containers/TestimonialSection";
import CTASection from "../containers/CTASection";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout
      hasGradient={false}
      title="How"
      pathname="/how"
      description="Learn how to get valuable insights about your designs with a single click!"
    >
      <HowItWorks />
      <TestimonialSection startAt={1} />
      {/* <CTASection /> */}
    </MainLayout>
  );
};
