import React from "react";
import Block from "./Block";
import SectionLayout from "../../components/SectionLayout";
import data from "./data.js";

const Blocks = ({}) => {
  return (
    <SectionLayout
      sectionId="how-it-works"
      title="How VisualEyes works"
      description="Get valuable insights about your designs with a single click!"
      style={{ margin: "0" }}
    >
      {data.how.map((block, index) => (
        <Block
          key={index}
          index={index}
          title={block.title}
          icon={block.icon}
          content={block.content}
          image={block.image}
          left={index % 2}
          additional={block.additional}
        />
      ))}
    </SectionLayout>
  );
};

export default Blocks;
